import TrpcWrapper from '../context/TrpcReact.context.tsx'
import { trpc } from '../../util/trpc.ts'
import { formatDateWithDay } from '../../util/date.util.ts'
import { useState } from 'react'
import Loading1 from '../icon/Loading1.tsx'
import { filter, isEmpty } from 'lodash-es'
import Dit from '../ket-qua/Dit.tsx'
import Dau from '../ket-qua/Dau.tsx'

export default () => {
  return (
    <TrpcWrapper>
      <Content />
    </TrpcWrapper>
  )
}

const Content = () => {
  const [listKq, setListKq] = useState<string[]>([])
  const [dau, setDau] = useState<Record<string, string[]>>({})
  const [dit, setDit] = useState<Record<string, string[]>>({})
  const [loadingDb, setLoadingDb] = useState<boolean>(false)

  trpc.main.trucTiep.useSubscription(undefined, {
    onData(data: string[]) {
      if (data[0] === 'end') {
        return
      }

      jQuery('#ketqua-table').hide()

      const totalNumber = filter(data, (it) => !isEmpty(it)).length
      const totalCurrentNumber = filter(listKq, (it) => !isEmpty(it)).length

      if (!isEmpty(listKq) && totalNumber < totalCurrentNumber) {
        return
      }

      setListKq(data)

      const newDau: Record<string, string[]> = {}
      const newDit: Record<string, string[]> = {}

      data
        .map((n) => n.slice(-2))
        .forEach((n) => {
          const d = n[0]
          const t = n[1]

          if (!newDau[d]) newDau[d] = []
          if (!newDit[t]) newDit[t] = []

          newDau[d].push(n)
          newDit[t].push(n)
        })

      setDau(newDau)
      setDit(newDit)

      if (filter(data, isEmpty).length === 1) {
        setLoadingDb(true)
      }
    },
    onError(err) {
      console.error('Subscription error:', err)
    },
  })

  if (isEmpty(listKq)) return null

  const KetQua = () => {
    return (
      <>
        <table className="ketqua" cellSpacing="1" cellPadding="9">
          <thead>
            <tr>
              <th colSpan={13} className="kqcell kq_ngay">
                {formatDateWithDay()}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="leftcol">ĐB</td>
              <td colSpan={12} className="kqcell kq_0">
                {loadingDb && (!listKq[0] || listKq[0] === '*') ? <Loading1 /> : listKq[0]}
              </td>
            </tr>
            <tr>
              <td className="leftcol">Nhất</td>
              <td colSpan={12} className="kqcell kq_1">
                {listKq[1] === '*' ? <Loading1 /> : listKq[1]}
              </td>
            </tr>
            <tr>
              <td className="leftcol">Nhì</td>
              <td colSpan={6} className="kqcell kq_2">
                {listKq[2] === '*' ? <Loading1 /> : listKq[2]}
              </td>
              <td colSpan={6} className="kqcell kq_2">
                {listKq[3] === '*' ? <Loading1 /> : listKq[3]}
              </td>
            </tr>
            <tr>
              <td rowSpan={2} className="leftcol">
                Ba
              </td>
              <td colSpan={4} className="kqcell kq_4">
                {listKq[4] === '*' ? <Loading1 /> : listKq[4]}
              </td>
              <td colSpan={4} className="kqcell kq_5">
                {listKq[5] === '*' ? <Loading1 /> : listKq[5]}
              </td>
              <td colSpan={4} className="kqcell kq_6">
                {listKq[6] === '*' ? <Loading1 /> : listKq[6]}
              </td>
            </tr>
            <tr>
              <td colSpan={4} className="kqcell kq_7">
                {listKq[7] === '*' ? <Loading1 /> : listKq[7]}
              </td>
              <td colSpan={4} className="kqcell kq_8">
                {listKq[8] === '*' ? <Loading1 /> : listKq[8]}
              </td>
              <td colSpan={4} className="kqcell kq_9">
                {listKq[9] === '*' ? <Loading1 /> : listKq[9]}
              </td>
            </tr>
            <tr>
              <td className="leftcol">Tư</td>
              <td colSpan={3} className="kqcell kq_10">
                {listKq[10] === '*' ? <Loading1 /> : listKq[10]}
              </td>
              <td colSpan={3} className="kqcell kq_11">
                {listKq[11] === '*' ? <Loading1 /> : listKq[11]}
              </td>
              <td colSpan={3} className="kqcell kq_12">
                {listKq[12] === '*' ? <Loading1 /> : listKq[12]}
              </td>
              <td colSpan={3} className="kqcell kq_13">
                {listKq[13] === '*' ? <Loading1 /> : listKq[13]}
              </td>
            </tr>
            <tr>
              <td rowSpan={2} className="leftcol">
                Năm
              </td>
              <td colSpan={4} className="kqcell kq_14">
                {listKq[14] === '*' ? <Loading1 /> : listKq[14]}
              </td>
              <td colSpan={4} className="kqcell kq_15">
                {listKq[15] === '*' ? <Loading1 /> : listKq[15]}
              </td>
              <td colSpan={4} className="kqcell kq_16">
                {listKq[16] === '*' ? <Loading1 /> : listKq[16]}
              </td>
            </tr>
            <tr>
              <td colSpan={4} className="kqcell kq_17">
                {listKq[17] === '*' ? <Loading1 /> : listKq[17]}
              </td>
              <td colSpan={4} className="kqcell kq_18">
                {listKq[18] === '*' ? <Loading1 /> : listKq[18]}
              </td>
              <td colSpan={4} className="kqcell kq_19">
                {listKq[19] === '*' ? <Loading1 /> : listKq[19]}
              </td>
            </tr>
            <tr>
              <td className="leftcol">Sáu</td>
              <td colSpan={4} className="kqcell kq_20">
                {listKq[20] === '*' ? <Loading1 /> : listKq[20]}
              </td>
              <td colSpan={4} className="kqcell kq_21">
                {listKq[21] === '*' ? <Loading1 /> : listKq[21]}
              </td>
              <td colSpan={4} className="kqcell kq_22">
                {listKq[22] === '*' ? <Loading1 /> : listKq[22]}
              </td>
            </tr>
            <tr>
              <td className="leftcol">Bảy</td>
              <td colSpan={3} className="kqcell kq_23">
                {listKq[23] === '*' ? <Loading1 /> : listKq[23]}
              </td>
              <td colSpan={3} className="kqcell kq_24">
                {listKq[24] === '*' ? <Loading1 /> : listKq[24]}
              </td>
              <td colSpan={3} className="kqcell kq_25">
                {listKq[25] === '*' ? <Loading1 /> : listKq[25]}
              </td>
              <td colSpan={3} className="kqcell kq_26">
                {listKq[26] === '*' ? <Loading1 /> : listKq[26]}
              </td>
            </tr>
            <tr className="lastrow">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </>
    )
  }

  return (
    <>
      <table
        className="hidden md:block"
        cellSpacing="0"
        cellPadding="5"
        style={{ margin: '5px 0' }}
      >
        <tbody>
          <tr>
            <td valign="top">
              <KetQua />
            </td>
            <td valign="top">
              <Dau dau={dau} />
            </td>
            <td valign="top">
              <Dit dit={dit} />
            </td>
          </tr>
        </tbody>
      </table>

      <table
        border={0}
        cellSpacing={0}
        cellPadding={5}
        style={{ margin: '5px 0' }}
        className="block md:hidden"
      >
        <tbody>
          <tr>
            <td colSpan={2} valign="top">
              <KetQua />
            </td>
          </tr>
          <tr>
            <td valign="top">
              <Dau dau={dau} />
            </td>
            <td valign="top">
              <Dit dit={dit} />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}
